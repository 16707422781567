<template>
  <div id="contractType">
    <Back title="合同类型"></Back>
    <div class="ul">
      <div
        class="padding"
        v-for="item in contractTypeList"
        :key="item.title"
        @click="linkTo(item.path, item.type, item.title)"
      >
        <div class="item">
          <img :src="item.imgSrc" :alt="item.title" />
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "@/components/back";
export default {
  components: { Back },
  data() {
    return {
      contractTypeList: [
        {
          title: "预定合同",
          imgSrc: require("../../assets/images/contract/contractType/yuding.png"),
          path: "/contract/reserveList",
        },
        {
          title: "新建未审批合同",
          imgSrc: require("../../assets/images/contract/contractType/xinjian.png"),
          path: "/contract/contractList",
          type: 3,
        },
        {
          title: "审批中合同",
          imgSrc: require("../../assets/images/contract/contractType/shengpizhong.png"),
          path: "/contract/contractList",
          type: 1,
        },
        {
          title: "生效合同",
          imgSrc: require("../../assets/images/contract/contractType/shengxiao.png"),
          path: "/contract/contractList",
          type: 2,
        },
      ],
    };
  },
  methods: {
    linkTo(path, type, title) {
      this.$router.push({
        path,
        query: { type, title },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#contractType {
  height: 100%;
  background: $bg-color;
  .ul {
    display: flex;
    flex-wrap: wrap;
  }
  .padding {
    width: 50%;
    padding: 0.1rem;
    background: $bg-color;
    box-sizing: border-box;
  }
  .item {
    height: 1.7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0.1rem;
    img {
      width: 0.7rem;
      margin-bottom: 0.1rem;
    }
  }
}
</style>

